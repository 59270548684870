import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Testimonials = () => {
  return (
    <>
      <section className="testimonial-area">
        <div className="custom-container">
          <div className="testimonial-slider swiper">
            <Swiper
              loop={true}
              spaceBetween={30}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              speed={800}
              easing="easeOutQuad"
              modules={[Navigation]} // Include the Navigation module
            >
              <SwiperSlide className="testimonial-item">
                <div className="testimonial-item-body">
                  <img decoding="async" className="animation-slide-right bg-shape" src="../assets/images/bg-shape-3-1.svg" alt="Background Shape" />

                  <span className="platform-name">
                    <img decoding="async" src="../assets/images/icon-linkedin-1.svg" alt="LinkedIn Icon" />
                  </span>
                  <h2>Platform helped me to increase my sales and revenue generated by bluebase technologies</h2>
                  <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                  <div className="author-box d-flex align-items-center">
                    <img decoding="async" src="../assets/images/testimonial-1-1.jpg" alt="Serana Belluci" />
                    <div className="author-box-content">
                      <h5>Serana Belluci</h5>
                      <p>Product Designer</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="testimonial-item">
                <div className="testimonial-item-body">
                  <img decoding="async" className="animation-slide-right bg-shape" src="../assets/images/bg-shape-3-1.svg" alt="Background Shape" />

                  <span className="platform-name">
                    <img decoding="async" src="../assets/images/icon-linkedin-1.svg" alt="LinkedIn Icon" />
                  </span>
                  <h2>Bluebase give the best service and top customer support. Really like to have more projects with you</h2>
                  <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                  <div className="author-box d-flex align-items-center">
                    <img decoding="async" src="../assets/images/testimonial-2-1.jpg" alt="Megan Clarke" />
                    <div className="author-box-content">
                      <h5>Megan Clarke</h5>
                      <p>CEO, Sanlab</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="testimonial-item">
                <div className="testimonial-item-body">
                  <img decoding="async" className="animation-slide-right bg-shape" src="../assets/images/bg-shape-3-1.svg" alt="Background Shape" />

                  <span className="platform-name">
                    <img decoding="async" src="../assets/images/icon-linkedin-1.svg" alt="LinkedIn Icon" />
                  </span>
                  <h2>We’ve been using bluebase for a few years, It is very simple and easy to approach team and management</h2>
                  <p>One aspect that sets IT Services apart is their proactive approach to security. In today's digital landscape, protecting our sensitive data is of utmost importance.</p>
                  <div className="author-box d-flex align-items-center">
                    <img decoding="async" src="../assets/images/testimonial-3-1.jpg" alt="Rachel Olsem" />
                    <div className="author-box-content">
                      <h5>Rachel Olsem</h5>
                      <p>Full Stack Developer</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

            {/* Navigation buttons */}
            <div className="swiper-button-next">
              <i className="iconoir-arrow-right"></i>
            </div>
            <div className="swiper-button-prev">
              <i className="iconoir-arrow-left"></i>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
