import React from "react";

const Footer = () => {
  return (
    <>
      <section className="contact-area">
        <div className="custom-container">
          <div className="custom-row">
            <div className="contact-form-wrap">
              <div className="contact-form-body">
                <h6 className="section-subtitle">Contact</h6>
                <h2 className="section-title">Lets get in touch</h2>
                <p>
                  You can reach us anytime via{" "}
                  <a target="_blank" href="mailto:bluebase@mail.com">
                    bluebase@mail.com
                  </a>
                </p>

                <div className="contact-form">
                  <div className="wpcf7 no-js" id="wpcf7-f313-p9-o1" lang="en-US" dir="ltr" data-wpcf7-id="313">
                    <div className="screen-reader-response">
                      <p role="status" aria-live="polite" aria-atomic="true"></p>
                      <ul></ul>
                    </div>
                    <form action="https://wpriverthemes.com/synck/#wpcf7-f313-p9-o1" method="post" className="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init">
                      <div style={{ display: "none" }}>
                        <input type="hidden" name="_wpcf7" value="313" />
                        <input type="hidden" name="_wpcf7_version" value="6.0.1" />
                        <input type="hidden" name="_wpcf7_locale" value="en_US" />
                        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f313-p9-o1" />
                        <input type="hidden" name="_wpcf7_container_post" value="9" />
                        <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                      </div>
                      <div className="input-row">
                        <div className="input-group">
                          <p>
                            <label for="first_name">First Name</label>
                            <span className="wpcf7-form-control-wrap" data-name="first_name">
                              <input
                                size="40"
                                maxlength="400"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                id="first_name"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="First Name"
                                value=""
                                type="text"
                                name="first_name"
                              />
                            </span>
                          </p>
                        </div>
                        <div className="input-group">
                          <p>
                            <label for="last_name">Last Name</label>
                            <span className="wpcf7-form-control-wrap" data-name="last_name">
                              <input
                                size="40"
                                maxlength="400"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                id="last_name"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Last Name"
                                value=""
                                type="text"
                                name="last_name"
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input-group">
                          <p>
                            <label for="email">Email</label>
                            <span className="wpcf7-form-control-wrap" data-name="email">
                              <input
                                size="40"
                                maxlength="400"
                                className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                                id="email"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Your Email"
                                value=""
                                type="email"
                                name="email"
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input-group phone-number">
                          <p>
                            <label for="phone_number">Phone Number</label>
                            <span className="wpcf7-form-control-wrap" data-name="phone_number">
                              <input
                                size="40"
                                maxlength="400"
                                className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel"
                                id="phone_number"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Your Number"
                                value=""
                                type="tel"
                                name="phone_number"
                              />
                            </span>
                          </p>
                        </div>
                        <div className="input-group">
                          <p>
                            <label for="country">Country</label>
                            <span className="wpcf7-form-control-wrap" data-name="country">
                              <input
                                size="40"
                                maxlength="400"
                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                id="homeland"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Your Country"
                                value=""
                                type="text"
                                name="country"
                              />
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input-group">
                          <p>
                            <label for="message">Message</label>
                            <span className="wpcf7-form-control-wrap" data-name="message">
                              <textarea
                                cols="40"
                                rows="10"
                                maxlength="2000"
                                className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                id="message"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Leave us a message...."
                                name="message"
                              ></textarea>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input-group input-checkbox">
                          <p>
                            <span className="wpcf7-form-control-wrap" data-name="acceptance">
                              <span className="wpcf7-form-control wpcf7-acceptance">
                                <span className="wpcf7-list-item">
                                  <label>
                                    <input type="checkbox" name="acceptance" value="1" id="privacy_policy_accept" aria-invalid="false" />
                                    <span className="wpcf7-list-item-label">
                                      You agree to our <a href="#">terms and conditions.</a>
                                    </span>
                                  </label>
                                </span>
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input-group">
                          <p>
                            <button id="submit" className="theme-btn">
                              Get Started
                            </button>
                          </p>
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input-group alert-notification">
                          <div id="alert-message" className="alert-msg"></div>
                        </div>
                      </div>
                      <div className="wpcf7-response-output" aria-hidden="true"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-experience">
              <ul>
                <li>
                  <h2>
                    22+ <span>Years</span>
                  </h2>
                  <p>Field Experience</p>
                </li>
                <li>
                  <h2>
                    950+ <span>Projects</span>
                  </h2>
                  <p>Done Around World</p>
                </li>
                <li>
                  <h2>99%</h2>
                  <p>Client Satisfaction</p>
                </li>
                <li>
                  <h2>
                    1995+ <span>Year</span>
                  </h2>
                  <p>Established On</p>
                </li>
                <li>
                  <h2>
                    2 <span>Mins</span>
                  </h2>
                  <p>Response Time</p>
                </li>
              </ul>
            </div>

            <div className="contact-infos">
              <div className="contact-infos-inner">
                <div className="contact-info">
                  <img decoding="async" src="../assets/images/support-icon.svg" />
                  <h4>Contact Info</h4>
                  <p>
                    +1 455 1482 236 <br />
                    Bluebase@mail.com
                  </p>
                </div>
                <div className="contact-info">
                  <img decoding="async" src="../assets/images/map-icon.svg" />
                  <h4>Visit our office</h4>
                  <p>16/9, Down Street Edinburgh, Scotland United Kingdom</p>
                </div>

                <ul className="contact-social-links">
                  <li>
                    <a target="_blank" href="#">
                      <i className="iconoir-dribbble"></i>
                      Dribbble
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#">
                      <i className="iconoir-twitter"></i>
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#">
                      <i className="iconoir-instagram"></i>
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#">
                      <i className="iconoir-linkedin"></i>
                      linkedin
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-area">
        <img src="../assets/images/bg-shape-4.svg" alt="" className="animation-slide-right bg-shape" />

        <div className="footer-top">
          <div className="custom-container">
            <div className="custom-row align-items-end justify-content-between">
              <div className="left-content">
                <a href="index.html" className="logo">
                  <img src="../assets/images/logo-white.svg" alt="" />
                </a>
                <p>
                  We provide the expertise and support to <br />
                  propel your business forward.
                </p>

                <div className="wpcf7 no-js" id="wpcf7-f505-o2" lang="en-US" dir="ltr" data-wpcf7-id="505">
                  <div className="screen-reader-response">
                    <p role="status" aria-live="polite" aria-atomic="true"></p>
                    <ul></ul>
                  </div>
                  <form action="https://wpriverthemes.com/synck/#wpcf7-f505-o2" method="post" className="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init">
                    <div style={{ display: "none" }}>
                      <input type="hidden" name="_wpcf7" value="505" />
                      <input type="hidden" name="_wpcf7_version" value="6.0.1" />
                      <input type="hidden" name="_wpcf7_locale" value="en_US" />
                      <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f505-o2" />
                      <input type="hidden" name="_wpcf7_container_post" value="0" />
                      <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                    </div>
                    <div className="subscribe-box">
                      <p>
                        <span className="wpcf7-form-control-wrap" data-name="email">
                          <input
                            size="40"
                            maxlength="400"
                            className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Enter Your Email"
                            value=""
                            type="email"
                            name="email"
                          />
                        </span>
                        <button id="submit2" className="theme-btn">
                          Get Started
                        </button>
                      </p>
                    </div>
                    <div className="input-row">
                      <div className="input-group alert-notification">
                        <div id="alert-message-subscribe" className="alert-msg"></div>
                      </div>
                    </div>
                    <div className="wpcf7-response-output" aria-hidden="true"></div>
                  </form>
                </div>

                <div className="footer-clients d-flex align-items-center">
                  <div className="footer-client-img">
                    <img src="../assets/images/youtube.svg" alt="" />
                  </div>
                  <div className="footer-client-img">
                    <img src="../assets/images/webflow.svg" alt="" />
                  </div>
                  <div className="footer-client-img">
                    <img src="../assets/images/upwork.svg" alt="" />
                  </div>
                  <div className="footer-client-img">
                    <img src="../assets/images/shopify.svg" alt="" />
                  </div>
                </div>
              </div>

              <div className="right-content">
                <div className="right-content-inner">
                  <h3>Let’s get started on something great</h3>
                  <p>
                    Our team of IT experts looks forward to meeting with you <br />
                    and providing valuable insights tailored to your business.
                  </p>

                  <a href="#" className="theme-btn">
                    Get an appointment now
                  </a>

                  <div className="footer-experience d-flex align-items-center">
                    <div className="footer-experience-item">
                      <h2>
                        2 <span>Mins</span>
                      </h2>
                      <p>Response Time</p>
                    </div>
                    <div className="footer-experience-item">
                      <h2>99%</h2>
                      <p>Client Satisfaction</p>
                    </div>
                    <div className="footer-experience-item">
                      <h2>
                        22+ <span>Years</span>
                      </h2>
                      <p>Field Experience</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="custom-container">
            <div className="custom-row">
              <div className="footer-all-links-wrap justify-content-between d-flex">
                <div className="footer-links">
                  <h4>Services</h4>
                  <ul style={{ paddingLeft: "0" }}>
                    <div className="menu-footer-services-menu-links-container">
                      <ul style={{ paddingLeft: "0" }} id="menu-footer-services-menu-links" className="menu">
                        <li id="menu-item-2878" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2878">
                          <a href="service-details/index.html">IT Support</a>
                        </li>
                        <li id="menu-item-2879" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2879">
                          <a href="service-details/index.html">Web Design</a>
                        </li>
                        <li id="menu-item-2880" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2880">
                          <a href="service-details/index.html">Development</a>
                        </li>
                        <li id="menu-item-2881" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2881">
                          <a href="service-details/index.html">Cloud Things</a>
                        </li>
                        <li id="menu-item-2882" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2882">
                          <a href="service-details/index.html">E-Commerce</a>
                        </li>
                        <li id="menu-item-2883" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2883">
                          <a href="service-details/index.html">CRM Solutions</a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div>
                <div className="footer-links">
                  <h4>Company</h4>
                  <ul style={{ paddingLeft: "0" }}>
                    <div className="menu-footer-company-menu-links-container">
                      <ul style={{ paddingLeft: "0" }} id="menu-footer-company-menu-links" className="menu">
                        <li id="menu-item-3885" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3885">
                          <a href="blog/index.html">Blog</a>
                        </li>
                        <li id="menu-item-2146" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2146">
                          <a href="about-us/index.html">About Us</a>
                        </li>
                        <li id="menu-item-2145" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2145">
                          <a href="partners/index.html">Partners</a>
                        </li>
                        <li id="menu-item-3167" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3167">
                          <a href="careers/index.html">Careers</a>
                        </li>
                        <li id="menu-item-3889" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3889">
                          <a href="events/index.html">Events</a>
                        </li>
                        <li id="menu-item-2738" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2738">
                          <a href="team/index.html">Team</a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div>
                <div className="footer-links">
                  <h4>Product</h4>
                  <ul style={{ paddingLeft: "0" }}>
                    <div className="menu-footer-product-menu-links-container">
                      <ul style={{ paddingLeft: "0" }} id="menu-footer-product-menu-links" className="menu">
                        <li id="menu-item-3884" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3884">
                          <a href="case-studies/index.html">Case Studies</a>
                        </li>
                        <li id="menu-item-2463" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2463">
                          <a href="our-pricing/index.html">Our Pricing</a>
                        </li>
                        <li id="menu-item-2623" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2623">
                          <a href="features/index.html">Features</a>
                        </li>
                        <li id="menu-item-2630" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2630">
                          <a href="overview/index.html">Overview</a>
                        </li>
                        <li id="menu-item-4135" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4135">
                          <a href="new-releases/index.html">New Releases</a>
                        </li>
                        <li id="menu-item-2151" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2151">
                          <a href="solutions/index.html">Solutions</a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div>
                <div className="footer-links">
                  <h4>Our Fields</h4>
                  <ul style={{ paddingLeft: "0" }}>
                    <div className="menu-footer-our-fields-menu-links-container">
                      <ul style={{ paddingLeft: "0" }} id="menu-footer-our-fields-menu-links" className="menu">
                        <li id="menu-item-3169" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3169">
                          <a href="our-field-details/index.html">Healthcare</a>
                        </li>
                        <li id="menu-item-3170" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3170">
                          <a href="our-field-details/index.html">Banks</a>
                        </li>
                        <li id="menu-item-3171" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3171">
                          <a href="our-field-details/index.html">Logistics</a>
                        </li>
                        <li id="menu-item-3172" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3172">
                          <a href="our-field-details/index.html">Supermarkets</a>
                        </li>
                        <li id="menu-item-3173" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3173">
                          <a href="our-field-details/index.html">Industries</a>
                        </li>
                        <li id="menu-item-3174" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3174">
                          <a href="our-field-details/index.html">Hotels</a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div>
                <div className="footer-links">
                  <h4>Legal</h4>
                  <ul style={{ paddingLeft: "0" }}>
                    <div className="menu-footer-legal-menu-links-container">
                      <ul style={{ paddingLeft: "0" }} id="menu-footer-legal-menu-links" className="menu">
                        <li id="menu-item-3175" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3175">
                          <a href="#">Licenses</a>
                        </li>
                        <li id="menu-item-3176" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3176">
                          <a href="#">Settings</a>
                        </li>
                        <li id="menu-item-3177" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3177">
                          <a href="#">Cookies</a>
                        </li>
                        <li id="menu-item-3178" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3178">
                          <a href="#">Document</a>
                        </li>
                        <li id="menu-item-3179" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3179">
                          <a href="#">Terms</a>
                        </li>
                        <li id="menu-item-3180" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-3180">
                          <a href="#">Privacy</a>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </div>
              </div>

              <div className="footer-contact-info">
                <div className="footer-contact-info-item">
                  <h5>Phone</h5>
                  <p>
                    <a href="tel:+1-455-1482-236">+1-455-1482-236</a> <br />
                    <a href="tel:+1-938-740-75556">+1-938-740-7555</a>
                  </p>
                </div>
                <div className="footer-contact-info-item">
                  <h5>Mail</h5>
                  <p>
                    <a href="mailto:bluebase@mail.com">bluebase@mail.com</a>
                    <br />
                    <a href="mailto:mandrodio@mail.com">mandrodio@mail.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="custom-container">
            <div className="custom-row d-flex align-items-center justify-content-between">
              <ul className="social-links d-flex align-items-center">
                <li>
                  <a href="#">
                    <i className="iconoir-dribbble"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="iconoir-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="iconoir-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="iconoir-linkedin"></i>
                  </a>
                </li>
              </ul>

              <p>
                &copy; 2023 All rights reserved by <a href="https://themeforest.net/user/wordpressriver/portfolio">WordpressRiver</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
