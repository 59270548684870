import React from "react";

const About = () => {
  return (
    <>
      <section className="about-area">
        <div className="custom-container">
          <div className="custom-row justify-content-between align-items-center">
            <div className="left-content">
              <h6 className="section-subtitle">CONSULTING EXCELLENCE</h6>
              <h2 className="section-title">Best pathway to our clients.</h2>
              <p>Our consulting process begins with a thorough assessment of your current IT infrastructure, workflows, and pain points.</p>
              <ul>
                <li>
                  <i className="icon-check las la-check"></i> 24/7 Full Service Support
                </li>
                <li>
                  <i className="icon-check las la-check"></i> Immediate Response
                </li>
                <li>
                  <i className="icon-check las la-check"></i> Easy to Approach us
                </li>
              </ul>
            </div>

            <div className="right-content">
              <div className="about-timeline">
                <div className="about-timeline-item">
                  <div className="about-timeline-item-inner">
                    <img decoding="async" src="../assets/images/bg-shape-2-1.svg" className="line-shape" />

                    <span className="number">01 </span>

                    <h4>Discovery and Analysis</h4>
                    <p>Perform a analysis of the client's existing IT systems.</p>
                  </div>
                </div>
                <div className="about-timeline-item">
                  <div className="about-timeline-item-inner">
                    <img decoding="async" src="../assets/images/bg-shape-2-1.svg" className="line-shape" />

                    <span className="number">02 </span>

                    <h4>Tailored Solutions</h4>
                    <p>Develop IT solutions based on the analysis phase.</p>
                  </div>
                </div>
                <div className="about-timeline-item">
                  <div className="about-timeline-item-inner">
                    <img decoding="async" src="../assets/images/bg-shape-2-1.svg" className="line-shape" />

                    <span className="number">03 </span>

                    <h4>Deployment and Support</h4>
                    <p>Regularly communicate with our client to any concern.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
