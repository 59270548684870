import React from "react";
import Hero from "./Sections/Hero/Hero";
import Clients from "./Sections/Clients/Clients";
import HowWeDo from "./Sections/HowWeDo/HowWeDo";
import Services from "./Sections/Services/Services";
import CaseStudy from "./Sections/CaseStudy/CaseStudy";
import About from "./Sections/About/About";
import Testimonials from "./Sections/Testimonials/Testimonials";
import Projects from "./Sections/Projects/Projects";
import News from "./Sections/News/News";
import Features from "./Sections/Features/Features";

const Home = () => {
  return (
    <>
      <Hero />
      <Clients />
      <HowWeDo />
      <Services />
      <CaseStudy />
      <About />
      <Testimonials />
      <Projects />
      <News />
      <Features />
    </>
  );
};

export default Home;
