import React from "react";

const HowWeDo = () => {
  return (
    <>
      <section className="how-we-do-area">
        <div className="custom-container">
          <div className="custom-row">
            <img decoding="async" src="../../../assets/images/bg-shape-1-1.svg" alt="Shape" className="animation-slide-left how-we-do-bg" />
            <div className="how-we-do-left-content">
              <div className="top">
                <h6 className="section-subtitle">Our Model</h6>
                <h2 className="section-title">How we do</h2>
                <p>Save time and money with our powerful method.</p>
              </div>

              <a href="how-we-do/index.html" className="theme-btn">
                {" "}
                Learn More <i className="iconoir-arrow-up-right"></i>{" "}
              </a>
            </div>
            <div className="how-we-do-right-content">
              <div className="how-we-do-items d-flex align-items-center justify-content-center">
                <div className="how-we-do-card">
                  <div className="circle-shape"></div>
                  <div className="line-shape"></div>

                  <div className="how-we-do-icon">
                    <img decoding="async" src="../../../assets/images/hwd-icon-1.svg" />
                  </div>

                  <div className="how-we-do-content">
                    <h5>
                      <a href="how-we-do-single/index.html"> Brainstroming </a>
                    </h5>

                    <p>Ideas</p>
                  </div>
                </div>

                <div className="how-we-do-card">
                  <div className="circle-shape"></div>
                  <div className="line-shape"></div>

                  <div className="how-we-do-icon">
                    <img decoding="async" src="../../../assets/images/hwd-icon-2.svg" />
                  </div>
                  <div className="how-we-do-content">
                    <h5>
                      <a href="how-we-do-single/index.html"> Product </a>
                    </h5>
                    <p>Design</p>
                  </div>
                </div>

                <div className="how-we-do-card">
                  <div className="circle-shape"></div>
                  <div className="line-shape"></div>

                  <div className="how-we-do-icon">
                    <img decoding="async" src="../../../assets/images/hwd-icon-3.svg" />
                  </div>
                  <div className="how-we-do-content">
                    <h5>
                      <a href="how-we-do-single/index.html"> Front-End </a>
                    </h5>
                    <p>Development</p>
                  </div>
                </div>
              </div>

              <div className="how-we-do-items d-flex align-items-center justify-content-center">
                <div className="how-we-do-card">
                  <div className="circle-shape"></div>
                  <div className="line-shape"></div>

                  <div className="how-we-do-icon">
                    <img decoding="async" src="../../../assets/images/hwd-icon-4.svg" />
                  </div>
                  <div className="how-we-do-content">
                    <h5>
                      <a href="how-we-do-single/index.html"> SEO </a>
                    </h5>
                    <p>Optimization</p>
                  </div>
                </div>

                <div className="how-we-do-card">
                  <div className="circle-shape"></div>
                  <div className="line-shape"></div>

                  <div className="how-we-do-icon">
                    <img decoding="async" src="../../../assets/images/hwd-icon-5.svg" />
                  </div>
                  <div className="how-we-do-content">
                    <h5>
                      <a href="how-we-do-single/index.html"> Back-End </a>
                    </h5>
                    <p>Development</p>
                  </div>
                </div>
              </div>

              <div className="how-we-do-items d-flex align-items-center justify-content-center">
                <div className="how-we-do-card">
                  <div className="circle-shape"></div>
                  <div className="line-shape"></div>

                  <div className="how-we-do-icon">
                    <img decoding="async" src="../../../assets/images/hwd-icon-6.svg" />
                  </div>
                  <div className="how-we-do-content">
                    <h5>
                      <a href="how-we-do-single/index.html"> Digital </a>
                    </h5>
                    <p>Marketing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowWeDo;
