import React from "react";

const Hero = () => {
  return (
    <>
      <section className="hero-empowerment-area">
        <div className="custom-container">
          <div className="custom-row align-items-center">
            <div className="hero-empowerment-left-content">
              <h6 className="section-subtitle">EMPOWERMENT</h6>
              <h1 className="section-title fade-in">Seamless IT for your business, boosting your growth.</h1>
              <p>We provide the expertise and support to propel your business forward in the digital landscape.</p>
              <div className="btns-group d-flex">
                <a href="#" className="theme-btn">
                  Learn More
                </a>

                <a href="contact/index.html" className="theme-btn2">
                  Let&#039;s Talk <i className="iconoir-arrow-up-right"></i>{" "}
                </a>
              </div>
            </div>

            <div className="hero-empowerment-right-content">
              <div className="top-content">
                <img decoding="async" className="desktop fade-in" src="../../../assets/images/bg1-1-1.png" />

                <div className="experience-box simple-shadow bounce-in">
                  <div className="experience-body d-flex align-items-center">
                    <img decoding="async" src="../../../assets/images/icon1-1.svg" />

                    <div className="experience-content d-flex align-items-center">
                      <h1>+8</h1>
                      <p>
                        Years <span>Experience</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bottom-content d-flex">
                <div className="our-expert-team-box simple-shadow bounce-in delay-2">
                  <a href="team/index.html">
                    <div className="our-expert-team-box-inner d-flex align-items-center">
                      <div className="imgs imgs1 d-flex align-items-center">
                        <img decoding="async" src="../../../assets/images/small-img-4-1.png" />
                        <img decoding="async" src="../../../assets/images/small-img-3-1.png" />
                        <img decoding="async" src="../../../assets/images/small-img-2-1.png" />
                        <img decoding="async" src="../../../assets/images/small-img-1-1.png" />
                      </div>
                      <p>
                        Meet <span>Our Experts</span>
                      </p>
                    </div>
                  </a>
                </div>

                <div className="google-reviews-box simple-shadow bounce-in delay-3">
                  <div className="left">
                    <span>Verified by</span>
                    <img decoding="async" src="../../../assets/images/icon2-1.svg" />
                  </div>
                  <div className="right">
                    <div className="stars d-flex align-items-center">
                      <i className="las la-star"></i>
                      <i className="las la-star"></i>
                      <i className="las la-star"></i>
                      <i className="las la-star"></i>
                      <i className="las la-star"></i>
                    </div>
                    <p>
                      3245 <span>Reviews</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
