import React from "react";

const Services = () => {
  return (
    <>
      <section className="service-area">
        <div className="custom-container">
          <div className="service-section-header section-header d-flex align-items-end justify-content-between">
            <div className="left">
              <h6 className="section-subtitle">WHAT WE’RE OFFERING</h6>
              <h2 className="section-title">Dealing in all professional IT services.</h2>
            </div>
            <p>One fundamental aspect of IT services is infrastructure management. This involves the design, implementation, and maintenance of the hardware, software, networks, and servers.</p>
          </div>

          <div className="services-list">
            <div className="service-card simple-shadow">
              <img decoding="async" src="../assets/images/service-icon-1-2.svg" className="service-icon" />
              <h4>
                <a href="service-details/index.html">Development</a>
              </h4>
              <p>Our development is pixel perfect in all ways.</p>
            </div>
            <div className="service-card simple-shadow">
              <img decoding="async" src="../assets/images/service-icon-2-2.svg" className="service-icon" />
              <h4>
                <a href="service-details/index.html">Woo Commerce</a>
              </h4>
              <p>We have a best team for your shopping websites.</p>
            </div>
            <div className="service-card simple-shadow">
              <img decoding="async" src="../assets/images/service-icon-3-2.svg" className="service-icon" />
              <h4>
                <a href="service-details/index.html">CRM Solutions</a>
              </h4>
              <p>We enhance customer experiences for success.</p>
            </div>
            <div className="service-card simple-shadow">
              <img decoding="async" src="../assets/images/service-icon-4-2.svg" className="service-icon" />
              <h4>
                <a href="service-details/index.html">Web Design</a>
              </h4>
              <p>We create vibrant, intuitive and minimalist web</p>
            </div>
            <div className="service-card simple-shadow">
              <span className="service-badge">Free!</span>

              <img decoding="async" src="../assets/images/service-icon-5-2.svg" className="service-icon" />
              <h4>
                <a href="service-details/index.html">IT Support</a>
              </h4>
              <p>We offers expert assistance for your IT issues.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
