import React from "react";

const News = () => {
  return (
    <>
      <section className="news-area">
        <div className="custom-container">
          <div className="section-header d-flex align-items-end justify-content-between">
            <div className="left">
              <h6 className="section-subtitle">INTERESTING ARTICLES</h6>
              <h2 className="section-title">Read daily news about startup companies</h2>
            </div>
            <p>
              In today&#039;s rapidly evolving world, technology is constantly shaping the way we live and interact. From artificial intelligence and automation to virtual reality, the pace of
              technological advancements is staggering.
            </p>
          </div>

          <div className="sticky-news d-flex card-h">
            <div className="news-img-box">
              <img decoding="async" src="../assets/images/news-1.png" />
            </div>

            <div className="news-content">
              <h6 className="section-subtitle section-subtitle1">MOBILE DEVELOPMENT</h6>
              <h2 className="section-title">
                <a href="blog-details-4/index.html">OpenAI launches new alignment division to tackle risks of superintelligent AI</a>
              </h2>
              <p>Small UK merchants, businesses, and other enterprises can now use Tap to Pay on service to replace payment terminals at point of sale.</p>
              <ul>
                <li>
                  <i className="las la-check"></i> Collaboration Tools
                </li>
                <li>
                  <i className="las la-check"></i> Smart Reminders
                </li>
              </ul>

              <a href="blog-details-4/index.html" className="theme-btn">
                <i className="iconoir-arrow-up-right"></i>
              </a>
            </div>
          </div>

          <div className="news-items align-items-start">
            <div className="news-item card-h">
              <div className="news-img-box">
                <img decoding="async" src="../assets/images/news-2.jpg" />
              </div>
              <div className="news-item-body">
                <h6 className="section-subtitle">WEB DEVELOPMENT</h6>
                <h2 className="section-title">
                  <a href="blog-details-4/index.html">Bluebase launches Tap to Pay on smartphone for international and domestic business</a>
                </h2>
                <p>Small UK merchants, businesses, and other enterprises can now use Tap to Pay on service to replace payment terminals at point of sale.</p>

                <ul>
                  <li>
                    <i className="las la-check"></i> Collaboration Tools
                  </li>
                  <li>
                    <i className="las la-check"></i> Smart Reminders
                  </li>
                  <li>
                    <i className="las la-check"></i> Requirement
                  </li>
                  <li>
                    <i className="las la-check"></i> Task Management
                  </li>
                </ul>
                <a href="blog-details-4/index.html" className="theme-btn">
                  <i className="iconoir-arrow-up-right"></i>
                </a>
              </div>
            </div>
            <div className="news-item card-h">
              <div className="news-img-box">
                <img decoding="async" src="../assets/images/news-3.jpg" />
              </div>
              <div className="news-item-body">
                <h6 className="section-subtitle">CLOUD COMPUTING</h6>
                <h2 className="section-title">
                  <a href="blog-details-4/index.html">Google Bard launches in EU, overcoming data privacy concerns in the region</a>
                </h2>
                <p>Small UK merchants, businesses, and other enterprises can now use Tap to Pay on service to replace payment terminals at point of sale.</p>

                <ul>
                  <li>
                    <i className="las la-check"></i> Collaboration Tools
                  </li>
                  <li>
                    <i className="las la-check"></i> Smart Reminders
                  </li>
                  <li>
                    <i className="las la-check"></i> Requirement
                  </li>
                </ul>
                <a href="blog-details-4/index.html" className="theme-btn">
                  <i className="iconoir-arrow-up-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
