import React from "react";

const Header = () => {
  return (
    <>
      <header className="header-area">
        <div className="custom-container">
          <div className="custom-row align-items-center justify-content-between">
            <div className="header-left d-flex align-items-center">
              <a href="index.html" className="logo">
                <img src="../../../assets/images/logo.svg" alt="" />
              </a>

              <div className="header-left-right">
                <a href="contact/index.html" className="theme-btn">
                  Contact Us
                </a>

                <span className="menu-bar">
                  <i className="las la-bars"></i>
                </span>
              </div>
              <nav className="navbar-wrapper">
                <span className="close-menu-bar">
                  <i className="las la-times"></i>
                </span>
                <ul style={{ marginBottom: "0" }}>
                  <li className="dropdown-menu-item home-menu" data-menu-index="0">
                    <a href="index.html">Home</a>

                    <span className="dropdown-menu-item-icon">
                      <i className="las la-angle-down"></i>
                    </span>
                    <ul className="dropdown-menu">
                      <div className="menu-header-home-menu-links-container">
                        <ul id="menu-header-home-menu-links" className="menu">
                          <li id="menu-item-4803" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-4803">
                            <a href="index.html" aria-current="page">
                              IT Services
                            </a>
                          </li>
                          <li id="menu-item-4804" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4804">
                            <a href="home-version2/index.html">Business Consulting</a>
                          </li>
                        </ul>
                      </div>
                    </ul>
                  </li>

                  <li className="mega-menu-item company-menu" data-menu-index="0">
                    <a href="company/index.html">Company</a>

                    <span className="dropdown-menu-item-icon">
                      <i className="las la-angle-down"></i>
                    </span>
                    <div className="mega-menu mega-menu-company">
                      <div className="mega-menu-inner">
                        <div className="custom-container d-flex">
                          <div className="left">
                            <div className="mega-menu-link-wrap d-flex justify-content-between">
                              <div className="mega-menu-link">
                                <h4>Get Started</h4>
                                <ul>
                                  <div className="menu-header-get-started-menu-links-container">
                                    <ul id="menu-header-get-started-menu-links" className="menu">
                                      <li id="menu-item-4762" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4762">
                                        <a href="#">Setup 101</a>
                                      </li>
                                      <li id="menu-item-4763" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4763">
                                        <a href="#">Adding users</a>
                                      </li>
                                      <li id="menu-item-4764" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4764">
                                        <a href="#">Video tutorials</a>
                                      </li>
                                      <li id="menu-item-4765" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4765">
                                        <a href="#">Libraries and SDKs</a>
                                      </li>
                                      <li id="menu-item-4766" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4766">
                                        <a href="#">Plugins</a>
                                      </li>
                                      <li id="menu-item-4767" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4767">
                                        <a href="#">Templates</a>
                                      </li>
                                    </ul>
                                  </div>
                                </ul>
                              </div>

                              <div className="mega-menu-links d-flex">
                                <div className="mega-menu-link">
                                  <h4>Company</h4>
                                  <ul>
                                    <div className="menu-header-company-menu-links-container">
                                      <ul id="menu-header-company-menu-links" className="menu">
                                        <li id="menu-item-4768" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4768">
                                          <a href="about-us/index.html">About Us</a>
                                        </li>
                                        <li id="menu-item-4772" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4772">
                                          <a href="partners/index.html">Partners</a>
                                        </li>
                                        <li id="menu-item-4770" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4770">
                                          <a href="careers/index.html">Careers</a>
                                        </li>
                                        <li id="menu-item-4771" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4771">
                                          <a href="events/index.html">Events</a>
                                        </li>
                                        <li id="menu-item-4773" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4773">
                                          <a href="team/index.html">Team</a>
                                        </li>
                                        <li id="menu-item-4769" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4769">
                                          <a href="blog/index.html">Blog</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </ul>
                                </div>
                                <div className="mega-menu-link">
                                  <h4>Product</h4>
                                  <ul>
                                    <div className="menu-header-product-menu-links-container">
                                      <ul id="menu-header-product-menu-links" className="menu">
                                        <li id="menu-item-4777" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4777">
                                          <a href="overview/index.html">Overview</a>
                                        </li>
                                        <li id="menu-item-4778" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4778">
                                          <a href="our-pricing/index.html">Our Pricing</a>
                                        </li>
                                        <li id="menu-item-4779" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4779">
                                          <a href="features/index.html">Features</a>
                                        </li>
                                        <li id="menu-item-4776" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4776">
                                          <a href="case-studies/index.html">Case Studies</a>
                                        </li>
                                        <li id="menu-item-4774" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4774">
                                          <a href="new-releases/index.html">New Releases</a>
                                        </li>
                                        <li id="menu-item-4775" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4775">
                                          <a href="solutions/index.html">Solutions</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </ul>
                                </div>
                                <div className="mega-menu-link">
                                  <h4>Legal</h4>
                                  <ul>
                                    <div className="menu-header-legal-menu-links-container">
                                      <ul id="menu-header-legal-menu-links" className="menu">
                                        <li id="menu-item-4780" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4780">
                                          <a href="#">Licenses</a>
                                        </li>
                                        <li id="menu-item-4781" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4781">
                                          <a href="#">Settings</a>
                                        </li>
                                        <li id="menu-item-4782" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4782">
                                          <a href="#">Cookies</a>
                                        </li>
                                        <li id="menu-item-4783" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4783">
                                          <a href="#">Document</a>
                                        </li>
                                        <li id="menu-item-4784" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4784">
                                          <a href="#">Terms</a>
                                        </li>
                                        <li id="menu-item-4785" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4785">
                                          <a href="#">Privacy</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="mega-meu-footer d-flex align-items-center justify-content-between w-full">
                              <ul className="mega-menu-social d-flex align-items-center">
                                <li>
                                  <a href="#">
                                    <i className="iconoir-dribbble"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-twitter"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-instagram"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-linkedin"></i>
                                  </a>
                                </li>
                              </ul>

                              <p>
                                Looking for new career? <a href="careers/index.html">We&#039;re hiring</a>
                              </p>
                            </div>
                          </div>

                          <div className="right">
                            <div className="mega-menu-ads">
                              <img src="wp-content/uploads/2024/09/iphone-13-1.jpg" alt="" />

                              <h3>Solution in your hand</h3>
                              <p>
                                Develop IT solutions based on the
                                <br />
                                analysis phase.
                              </p>
                              <a href="case-studie-single/index.html">View more</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="mega-menu-item portfolio-menu" data-menu-index="0">
                    <a href="portfolio/index.html">Portfolio</a>

                    <span className="dropdown-menu-item-icon">
                      <i className="las la-angle-down"></i>
                    </span>
                    <div className="mega-menu mega-menu-portfolio">
                      <div className="mega-menu-inner">
                        <div className="custom-container d-flex">
                          <div className="left">
                            <div className="mega-menu-link-wrap d-flex align-items-start justify-content-between">
                              <a href="portfolio-detail/index.html">
                                <div className="mega-menu-portfolio-card">
                                  <div className="img-box">
                                    <img src="wp-content/uploads/2024/09/portfolio-mega-menu-1.jpg" alt="" />
                                  </div>
                                  <div className="content-box">
                                    <h4>E-commerce</h4>
                                    <p>
                                      we undertook a project to <br />
                                      migrate their existing.
                                    </p>
                                  </div>
                                </div>
                              </a>

                              <a href="portfolio-detail/index.html">
                                <div className="mega-menu-portfolio-card">
                                  <div className="img-box">
                                    <img src="wp-content/uploads/2024/09/portfolio-mega-menu-2.jpg" alt="" />
                                  </div>
                                  <div className="content-box">
                                    <h4>App Development</h4>
                                    <p>
                                      The mobile application has <br />
                                      significantly improved.
                                    </p>
                                  </div>
                                </div>
                              </a>

                              <a href="portfolio-detail/index.html">
                                <div className="mega-menu-portfolio-card">
                                  <div className="img-box">
                                    <img src="wp-content/uploads/2024/09/portfolio-mega-menu-3.jpg" alt="" />
                                  </div>
                                  <div className="content-box">
                                    <h4>SAAS Integration</h4>
                                    <p>
                                      We partnered with DEF to <br />
                                      upgrade their outdated IT.
                                    </p>
                                  </div>
                                </div>
                              </a>

                              <a href="portfolio-detail/index.html">
                                <div className="mega-menu-portfolio-card">
                                  <div className="img-box">
                                    <img src="wp-content/uploads/2024/09/portfolio-mega-menu-4.jpg" alt="" />
                                  </div>
                                  <div className="content-box">
                                    <h4>Virtual Reality</h4>
                                    <p>
                                      Enter into the virtual reality <br />
                                      world for real experience.
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>

                            <div className="mega-meu-footer d-flex align-items-center justify-content-between w-full">
                              <ul className="mega-menu-social d-flex align-items-center">
                                <li>
                                  <a href="#">
                                    <i className="iconoir-dribbble"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-twitter"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-instagram"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-linkedin"></i>
                                  </a>
                                </li>
                              </ul>
                              <p>
                                <a href="portfolio-detail/index.html">
                                  View Portfolio <i className="iconoir-arrow-up-right"></i>
                                </a>
                              </p>
                            </div>
                          </div>
                          <div className="right">
                            <div className="mega-menu-ads">
                              <img src="wp-content/uploads/2024/09/macbook.jpg" alt="" />

                              <h3>Mobile app for a new era</h3>
                              <p>
                                Download slack in your mobile for <br />
                                your daily usage.
                              </p>
                              <a href="case-studie-single/index.html">View more</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="mega-menu-item service-menu" data-menu-index="0">
                    <a href="services/index.html">Services</a>

                    <span className="dropdown-menu-item-icon">
                      <i className="las la-angle-down"></i>
                    </span>
                    <div className="mega-menu mega-menu-service">
                      <div className="mega-menu-inner">
                        <div className="custom-container d-flex">
                          <div className="left">
                            <div className="mega-menu-link-wrap d-flex align-items-start justify-content-between">
                              <div className="mega-menu-service-cards align-items-start">
                                <a href="service-details/index.html">
                                  <div className="mega-menu-service-card">
                                    <div className="icon">
                                      <img src="wp-content/uploads/2024/09/hwd-icon-1.svg" alt="" />
                                    </div>
                                    <div className="content">
                                      <h3>Brainstroming</h3>
                                      <p>Ideas</p>
                                    </div>
                                  </div>
                                </a>

                                <a href="service-details/index.html">
                                  <div className="mega-menu-service-card">
                                    <span className="icon">
                                      <img src="wp-content/uploads/2024/09/hwd-icon-2.svg" alt="" />
                                    </span>
                                    <div className="content">
                                      <h3>Product</h3>
                                      <p>Design</p>
                                    </div>
                                  </div>
                                </a>

                                <a href="service-details/index.html">
                                  <div className="mega-menu-service-card">
                                    <span className="icon">
                                      <img src="wp-content/uploads/2024/09/hwd-icon-3.svg" alt="" />
                                    </span>
                                    <div className="content">
                                      <h3>SEO</h3>
                                      <p>Optimization</p>
                                    </div>
                                  </div>
                                </a>

                                <a href="service-details/index.html">
                                  <div className="mega-menu-service-card">
                                    <span className="icon">
                                      <img src="wp-content/uploads/2024/09/hwd-icon-4.svg" alt="" />
                                    </span>
                                    <div className="content">
                                      <h3>Front-End</h3>
                                      <p>Development</p>
                                    </div>
                                  </div>
                                </a>
                              </div>

                              <div className="mega-menu-links d-flex">
                                <div className="mega-menu-link">
                                  <h4>Services</h4>
                                  <ul>
                                    <div className="menu-header-services-menu-links-container">
                                      <ul id="menu-header-services-menu-links" className="menu">
                                        <li id="menu-item-4792" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4792">
                                          <a href="service-details/index.html">Development</a>
                                        </li>
                                        <li id="menu-item-4793" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4793">
                                          <a href="service-details/index.html">Web Design</a>
                                        </li>
                                        <li id="menu-item-4794" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4794">
                                          <a href="service-details/index.html">IT Support</a>
                                        </li>
                                        <li id="menu-item-4795" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4795">
                                          <a href="service-details/index.html">E-Commerce</a>
                                        </li>
                                        <li id="menu-item-4796" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4796">
                                          <a href="service-details/index.html">Cloud Things</a>
                                        </li>
                                        <li id="menu-item-4797" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4797">
                                          <a href="service-details/index.html">CRM Solutions</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </ul>
                                </div>
                                <div className="mega-menu-link">
                                  <h4>Our Fields</h4>
                                  <ul>
                                    <div className="menu-header-our-fields-menu-links-container">
                                      <ul id="menu-header-our-fields-menu-links" className="menu">
                                        <li id="menu-item-4786" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4786">
                                          <a href="our-field-details/index.html">Healthcare</a>
                                        </li>
                                        <li id="menu-item-4787" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4787">
                                          <a href="our-field-details/index.html">Banks</a>
                                        </li>
                                        <li id="menu-item-4788" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4788">
                                          <a href="our-field-details/index.html">Logistics</a>
                                        </li>
                                        <li id="menu-item-4789" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4789">
                                          <a href="our-field-details/index.html">Supermarkets</a>
                                        </li>
                                        <li id="menu-item-4790" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4790">
                                          <a href="our-field-details/index.html">Industries</a>
                                        </li>
                                        <li id="menu-item-4791" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4791">
                                          <a href="our-field-details/index.html">Hotels</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="mega-meu-footer d-flex align-items-center justify-content-between w-full">
                              <ul className="mega-menu-social d-flex align-items-center">
                                <li>
                                  <a href="#">
                                    <i className="iconoir-dribbble"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-twitter"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-instagram"></i>
                                  </a>
                                </li>

                                <li>
                                  <a href="#">
                                    <i className="iconoir-linkedin"></i>
                                  </a>
                                </li>
                              </ul>

                              <p>
                                Looking for new career? <a href="careers/index.html">We&#039;re hiring</a>
                              </p>
                            </div>
                          </div>
                          <div className="right">
                            <div className="mega-menu-ads">
                              <img src="wp-content/uploads/2024/09/ipad.jpg" alt="" />

                              <h3>Our product hits</h3>
                              <p>
                                Our new best IT product of the <br />
                                year 2023.
                              </p>
                              <a href="case-studie-single/index.html">View more</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <a href="faq/index.html">FAQ</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header-right">
              <div className="header-contact-info d-flex align-items-center">
                <div className="phone-number">
                  <a href="tel:+1-938-740-7555">
                    {" "}
                    Call Us <i className="iconoir-arrow-up-right"></i>{" "}
                  </a>
                  +1-938-740-7555
                </div>

                <a href="contact/index.html" className="theme-btn">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
